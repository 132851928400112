import Vue from "vue";
import VueRouter from "vue-router";
// import HomeView from "../views/HomeView.vue";
const index = () => import("@/views/index.vue");
const Home = () => import("@/views/home/index.vue");
const FireDrill = () => import("@/views/fireDrill/index.vue");
const FireDrillDetail = () => import("@/views/fireDrill/detail/index.vue");
const Facility = () => import("@/views/facility/index.vue");
const FacilityDetail = () => import("@/views/facility/detail/index.vue");
const VisualizationMap = () => import("@/views/visualizationMap/index.vue");
Vue.use(VueRouter);
import state from "@/store/index";

const routes = [
  {
    path: "/",
    name: "Index",
    component: index,
    redirect: "/",
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      {
        path: "/fireDrill",
        name: "FireDrill",
        component: FireDrill,
        children: [],
      },
      {
        path: "/fireDrill/fireDrillDetail",
        name: "FireDrillDetail",
        meta: {
          isBack: true,
        },
        component: FireDrillDetail,
      },
      {
        path: "/facility",
        name: "Facility",
        component: Facility,
      },
      {
        path: "/facility/facilityDetail",
        name: "FacilityDetail",
        meta: {
          isBack: true,
        },
        component: FacilityDetail,
      },
      {
        path: "/VisualizationMapWarter",
        name: "VisualizationMapWarter",
        component: VisualizationMap,
      },
      {
        path: "/VisualizationMapCamera",
        name: "VisualizationMapCamera",
        component: VisualizationMap,
      },
      {
        path: "/VisualizationMapFireAlarm",
        name: "VisualizationMapFireAlarm",
        component: VisualizationMap,
      },
      {
        path: "/VisualizationMapMeteorological",
        name: "VisualizationMapMeteorological",
        component: VisualizationMap,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  // mode: "history",
});
//全局前置路由，配合浏览器localStorage进行鉴权操作
router.beforeEach((to, from, next) => {
  //首先，我们先看一看to和from参数，next就是执行的意思，不写页面是不会跳转的
  console.log(to, from, "路由");
  if (to.meta.isAuth) {
    //判断是否需要鉴权
    // if (localStorage.getItem("name") === "张三") {
    //   next();
    // } else {
    //   alert("不好意思，姓名不对，没有权限");
    // }
  } else {
    next();
  }
});

// 全局后置路由，简单写一个弹窗，在进入每一个路由组件后弹出一句话
router.afterEach((e) => {
  console.log(e, state);
  if (e.meta.isBack) {
    state.commit("SETISBACK", true);
  } else {
    state.commit("SETISBACK", false);
  }
});

export default router;
