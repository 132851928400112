import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import modules from "./modules";
export default new Vuex.Store({
  state: {
    headerBack: false,
    isMapDetail:false,
  },
  getters: {
    isBack: (state) => state.headerBack,
    isMapDetail:(state)=>state.isMapDetail
  },
  mutations: {
    SETISBACK(state, value) {
      state.headerBack = value;
    },
    SETMAPDETAIL(state, value) {
      state.isMapDetail = value;
    },
  },
  modules,
});
